import titanAdvertiserSlice from 'products/titan/operators/advertiser/slices';
import titanAdvertisersSlice from 'products/titan/operators/advertisers/slices';
import titanCampaignSlice from 'products/titan/operators/campaign/slices';
import titanCampaignFlightSlice from 'products/titan/operators/campaign-flight/slices';
import titanCampaignNoteSlice from 'products/titan/operators/campaign-note/slices';
import titanCampaignNoteCategoriesListSlice from 'products/titan/operators/campaign-note-categories-list/slices';
import titanCampaignNotesSlice from 'products/titan/operators/campaign-notes/slices';
import titanCampaignRolePersonsSlice from 'products/titan/operators/campaign-role-persons/slices';
import titanCampaignRolesListSlice from 'products/titan/operators/campaign-roles-list/slices';
import titanCampaignSupplementalEventSourcesSlice from 'products/titan/operators/campaign-supplemental-event-sources/slices';
import titanCampaignTeamSlice from 'products/titan/operators/campaign-team/slices';
import titanCampaignsSlice from 'products/titan/operators/campaigns/slices';
import titanCreativeSlice from 'products/titan/operators/creative/slices';
import titanCreativeATitanCreativeAdServersListSlice from 'products/titan/operators/creative-ad-servers-list/slices';
import titanCreativeAddonsSlice from 'products/titan/operators/creative-addons-list/slices';
import titanCreativeBannerSizesListSlice from 'products/titan/operators/creative-banner-sizes-list/slices';
import titanCreativeMessagesSlice from 'products/titan/operators/creative-messages/slices';
import titanCreativeTypesListSlice from 'products/titan/operators/creative-types-list/slices';
import titanCreativesSlice from 'products/titan/operators/creatives/slices';
import titanDeliverySourcesListSlice from 'products/titan/operators/delivery-sources-list/slices';
import titanDeliveryTypesListSlice from 'products/titan/operators/delivery-types-list/slices';
import titanDeliveryValuesSlice from 'products/titan/operators/delivery-values/slices';
import titanDspsListSlice from 'products/titan/operators/dsps-list/slices';
import titanEventSourceSlice from 'products/titan/operators/event-source/slices';
import titanEventSourceAttributesSlice from 'products/titan/operators/event-source-attributes/slices';
import titanEventSourceCategoriesListSlice from 'products/titan/operators/event-source-categories-list/slices';
import titanEventSourceDataProviderS3ObjectsListSlice from 'products/titan/operators/event-source-data-provider-s3-objects-list/slices';
import titanEventSourceDataProvidersListSlice from 'products/titan/operators/event-source-data-providers-list/slices';
import titanEventSourceOperatorTypesListSlice from 'products/titan/operators/event-source-operator-types-list/slices';
import titanEventSourcePropertyTypesListSlice from 'products/titan/operators/event-source-property-types-list/slices';
import titanEventSourceTypesListSlice from 'products/titan/operators/event-source-types-list/slices';
import titanEventSourcesSlice from 'products/titan/operators/event-sources/slices';
import titanEventSourcesPerformanceSlice from 'products/titan/operators/event-sources-performance/slices';
import titanInventorySourcesListSlice from 'products/titan/operators/inventory-sources-list/slices';
import titanKpiTypesListSlice from 'products/titan/operators/kpi-types-list/slices';
import titanNotificationSlice from 'products/titan/operators/notification/slices';
import titanNotificationTypesListSlice from 'products/titan/operators/notification-types-list/slices';
import titanNotificationsSlice from 'products/titan/operators/notifications/slices';
import titanPageTypesListSlice from 'products/titan/operators/page-types-list/slices';
import titanPagesSlice from 'products/titan/operators/pages/slices';
import titanPartnerSlice from 'products/titan/operators/partner/slices';
import titanPartnersSlice from 'products/titan/operators/partners/slices';
import titanProductTypesListSlice from 'products/titan/operators/product-types-list/slices';
import titanSalesRegionsListSlice from 'products/titan/operators/sales-regions-list/slices';
import titanVirtualEventSourceSlice from 'products/titan/operators/virtual-event-source/slices';
import titanVirtualEventSourcesSlice from 'products/titan/operators/virtual-event-sources/slices';

export const titan = {
  titan_advertiser: titanAdvertiserSlice,
  titan_advertisers: titanAdvertisersSlice,
  titan_creative_types_list: titanCreativeTypesListSlice,
  titan_creative_ad_servers_list: titanCreativeATitanCreativeAdServersListSlice,
  titan_creative_addons_list: titanCreativeAddonsSlice,
  titan_creative_banner_sizes_list: titanCreativeBannerSizesListSlice,
  titan_creative_messages: titanCreativeMessagesSlice,
  titan_delivery_sources_list: titanDeliverySourcesListSlice,
  titan_dsps_list: titanDspsListSlice,
  titan_event_source: titanEventSourceSlice,
  titan_event_source_performance: titanEventSourcesPerformanceSlice,
  titan_event_sources: titanEventSourcesSlice,
  titan_campaign_supplemental_event_sources: titanCampaignSupplementalEventSourcesSlice,
  titan_event_source_attributes: titanEventSourceAttributesSlice,
  titan_event_source_categories_list: titanEventSourceCategoriesListSlice,
  titan_event_source_operator_types_list: titanEventSourceOperatorTypesListSlice,
  titan_event_source_property_types_list: titanEventSourcePropertyTypesListSlice,
  titan_event_source_types_list: titanEventSourceTypesListSlice,
  titan_inventory_sources_list: titanInventorySourcesListSlice,
  titan_kpi_types_list: titanKpiTypesListSlice,
  titan_partner: titanPartnerSlice,
  titan_partners: titanPartnersSlice,
  titan_product_types_list: titanProductTypesListSlice,
  titan_virtual_event_source: titanVirtualEventSourceSlice,
  titan_virtual_event_sources: titanVirtualEventSourcesSlice,
  titan_campaign_roles_list: titanCampaignRolesListSlice,
  titan_campaign_role_persons: titanCampaignRolePersonsSlice,
  titan_pages: titanPagesSlice,
  titan_page_types_list: titanPageTypesListSlice,
  titan_campaign: titanCampaignSlice,
  titan_campaigns: titanCampaignsSlice,
  titan_campaign_flight: titanCampaignFlightSlice,
  titan_campaign_note: titanCampaignNoteSlice,
  titan_campaign_notes: titanCampaignNotesSlice,
  titan_campaign_note_categories_list: titanCampaignNoteCategoriesListSlice,
  titan_creatives: titanCreativesSlice,
  titan_creative: titanCreativeSlice,
  titan_event_source_data_providers_list: titanEventSourceDataProvidersListSlice,
  titan_event_source_data_provider_s3_objects_list: titanEventSourceDataProviderS3ObjectsListSlice,
  titan_notification: titanNotificationSlice,
  titan_notifications: titanNotificationsSlice,
  titan_notification_types_list: titanNotificationTypesListSlice,
  titan_sales_regions_list: titanSalesRegionsListSlice,
  titan_delivery_values: titanDeliveryValuesSlice,
  titan_delivery_types_list: titanDeliveryTypesListSlice,
  titan_campaign_team: titanCampaignTeamSlice,
};
