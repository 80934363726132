import { HyperionCampaignDealSummary, HyperionCampaignRtbSummary } from '@cognitiv/galaxy-api';
import { HyperionReporting } from 'products/hyperion/operators/reporting/types';

export const hyperion_performance_summary_default = {
  bid_requests: 0,
  bid_rate: 0,
  win_rate: 0,
  impressions: 0,
  spend_usd: 0,
  cpm_usd: 0,
  kpi: 0,
  pacing: 0,
  bid_requests_formatted: '-',
  bid_rate_formatted: '-',
  win_rate_formatted: '-',
  impressions_formatted: '-',
  spend_usd_formatted: '-',
  cpm_usd_formatted: '-',
  kpi_formatted: '-',
  pacing_formatted: '-',
};

export const hyperion_rtb_summary_default = {
  date_formatted: '',
  cpm_usd_formatted: '',
  clicks_formatted: '',
  video_starts_formatted: '',
  video_completions_formatted: '',
  conversions_formatted: '',
  revenue_usd_formatted: '',
  margin_usd_formatted: '',
  pacing_target_usd_formatted: '',
  pacing_target_percentage_formatted: '',
  impressions_formatted: '',
  spend_usd_formatted: '',
  cost_of_media_usd_ssp_formatted: '',
  kpi_formatted: '',
  uuid: '',
  date: '',
  campaign_id: null,
  campaign_name: '',
  delivery_source_id: null,
  delivery_source: '',
  conversion_source_id: null,
  conversion_source: '',
  cpm_usd: null,
  clicks: null,
  video_starts: null,
  video_completions: null,
  conversions1: null,
  revenue_usd1: null,
  impressions: null,
  spend_usd: null,
  cost_of_media_usd_ssp: null,
  margin_usd: null,
  pacing_target_usd: 0,
  pacing_target_percentage: 0,
  kpi: null,
  kpi_type_id: null,
  kpi_type_name: '',
  kpi_unit_id: null,
  kpi_unit_name: '',
};

export const hyperion_campaign_performance_summary_default: HyperionCampaignDealSummary = {
  yesterday: hyperion_performance_summary_default,
  this_week: hyperion_performance_summary_default,
  this_month: hyperion_performance_summary_default,
  all_time: hyperion_performance_summary_default,
  kpi_unit_id: null,
  kpi_unit_name: '',
  kpi_type_id: null,
  kpi_type_name: '',
};

export const hyperion_campaign_rtb_summary_default: HyperionCampaignRtbSummary = {
  yesterday: hyperion_rtb_summary_default,
  this_week: hyperion_rtb_summary_default,
  this_month: hyperion_rtb_summary_default,
  all_time: hyperion_rtb_summary_default,
  kpi_unit_id: null,
  kpi_unit_name: '',
  kpi_type_id: null,
  kpi_type_name: '',
};

export const hyperion_deal_performance_summary_default: HyperionCampaignDealSummary = {
  yesterday: hyperion_performance_summary_default,
  this_week: hyperion_performance_summary_default,
  this_month: hyperion_performance_summary_default,
  all_time: hyperion_performance_summary_default,
  kpi_unit_id: null,
  kpi_unit_name: '',
  kpi_type_id: null,
  kpi_type_name: '',
};

export const hyperion_reporting_default: HyperionReporting = {
  campaign_performance_summary: hyperion_campaign_performance_summary_default,
  campaign_rtb_performance_summary: hyperion_campaign_rtb_summary_default,
  campaign_performance: [],
  campaign_rtb_performance: [],
  campaign_rtb_dcpm_performance: [],
  campaign_adserver: [],
  campaign_dsp: [],
  campaign_ssp: [],
  deal_performance_summary: hyperion_deal_performance_summary_default,
  deal_ssp_report: [],
};
