import { TitanFlight } from '@cognitiv/galaxy-api';

export const titan_flight_default: TitanFlight = {
  start_date_formatted: '',
  finish_date_formatted: '',
  flight_dates_formatted: '',
  days_remaining: '',
  days_in_campaign: '',
  uuid: '',
  flight_id: 0,
  flight_name: '',
  campaign_id: 0,
  campaign_name: '',
  advertiser_id: 0,
  advertiser_name: '',
  partner_id: 0,
  partner_name: '',
  start_date: '',
  finish_date: '',
  budget_type_id: 0,
  budget_type_name: '',
  budget_value: 0,
  state_id: 0,
  state_name: '',
  revision_id: null,
  salesforce_opportunity_identifier: '',
  salesforce_opportunity_name: '',
};
