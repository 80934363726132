import {
  HyperionAdvertiserDealAdServer,
  HyperionAdvertiserDealDsp,
  HyperionCampaignDealPerformance,
  HyperionCampaignDealSsp,
  HyperionCampaignDealSummary,
  HyperionCampaignRtbDcpmPerformance,
  HyperionCampaignRtbPerformance,
  HyperionCampaignRtbSummary,
  HyperionDealPerformanceSummary,
  HyperionDealSsp,
} from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hyperion_reporting_default } from 'products/hyperion/operators/reporting/defaults';
import { HyperionReporting } from 'products/hyperion/operators/reporting/types';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionReporting = { ...hyperion_reporting_default };

export const hyperionReportingSlice = createSlice({
  name: SLICE.HYPERION_REPORTING,
  initialState,
  reducers: {
    clearHyperionReporting: () => initialState,
    setHyperionReportingCampaignPerformanceSummary: (state, action: PayloadAction<HyperionCampaignDealSummary>) => {
      state.campaign_performance_summary = action.payload;
    },
    setHyperionReportingCampaignRtbPerformanceSummary: (state, action: PayloadAction<HyperionCampaignRtbSummary>) => {
      state.campaign_rtb_performance_summary = action.payload;
    },
    setHyperionReportingCampaignPerformance: (state, action: PayloadAction<HyperionCampaignDealPerformance[]>) => {
      state.campaign_performance = action.payload;
    },
    setHyperionReportingCampaignRtbPerformance: (state, action: PayloadAction<HyperionCampaignRtbPerformance[]>) => {
      state.campaign_rtb_performance = action.payload;
    },
    setHyperionReportingCampaignRtbDcpmPerformance: (state, action: PayloadAction<HyperionCampaignRtbDcpmPerformance[]>) => {
      state.campaign_rtb_dcpm_performance = action.payload;
    },
    setHyperionReportingCampaignAdserver: (state, action: PayloadAction<HyperionAdvertiserDealAdServer[]>) => {
      state.campaign_adserver = action.payload;
    },
    setHyperionReportingCampaignDsp: (state, action: PayloadAction<HyperionAdvertiserDealDsp[]>) => {
      state.campaign_dsp = action.payload;
    },
    setHyperionReportingCampaignSsp: (state, action: PayloadAction<HyperionCampaignDealSsp[]>) => {
      state.campaign_ssp = action.payload;
    },
    setHyperionReportingDealPerformanceSummary: (state, action: PayloadAction<HyperionDealPerformanceSummary>) => {
      state.deal_performance_summary = action.payload;
    },
    setHyperionReportingDealSspReport: (state, action: PayloadAction<HyperionDealSsp[]>) => {
      state.deal_ssp_report = action.payload;
    },
  },
});

export const {
  clearHyperionReporting,
  setHyperionReportingCampaignPerformanceSummary,
  setHyperionReportingCampaignRtbPerformanceSummary,
  setHyperionReportingCampaignPerformance,
  setHyperionReportingCampaignRtbPerformance,
  setHyperionReportingCampaignRtbDcpmPerformance,
  setHyperionReportingCampaignAdserver,
  setHyperionReportingCampaignDsp,
  setHyperionReportingCampaignSsp,
  setHyperionReportingDealPerformanceSummary,
  setHyperionReportingDealSspReport,
} = hyperionReportingSlice.actions;

export default hyperionReportingSlice.reducer;
