import libraDomainListSlice from 'products/libra/operators/domain-list/slices';
import libraDomainListsSlice from 'products/libra/operators/domain-lists/slices';
import libraDspIdentifierSlice from 'products/libra/operators/dsp-identifier/slices';
import libraDspIdentifiersSlice from 'products/libra/operators/dsp-identifiers/slices';

export const libra = {
  libra_dsp_identifier: libraDspIdentifierSlice,
  libra_dsp_identifiers: libraDspIdentifiersSlice,
  libra_domain_list: libraDomainListSlice,
  libra_domain_lists: libraDomainListsSlice,
};
