export enum SLICE {
  ORION_CAMPAIGNS = 'orion-campaigns',
  ORION_CAMPAIGN = 'orion-campaign',
  ORION_SEGMENTS = 'orion-segments',
  ORION_SEGMENT_TYPES = 'orion-segment-types',
  ORION_SEGMENT = 'orion-segment',
  ORION_DEAL = 'orion-deal',
  ORION_DEALS = 'orion-deals',
  ORION_DOMAIN_LIST = 'orion-domain-list',
}
